<template>
  <el-container class="pr propertyList">
    <el-tabs
      type="border-card"
      v-model="activeTab"
      class="w-100"
    >
      <el-tab-pane name="Facebook">
        <span slot="label"> 资产更新 </span>
        <facebook
          @toggleShowRecharge="toggleShowRecharge"
          :leave="activeTab"
          :queryAgencyList="queryAgencyList"
        ></facebook>
      </el-tab-pane>
      <!-- <el-tab-pane name="Tiktok">
        <span slot="label"> Tiktok </span>
        <tiktok
          @toggleShowRecharge="toggleShowRecharge"
          :leave="activeTab"
        ></tiktok>
      </el-tab-pane>
      <el-tab-pane name="Google">
        <span slot="label">Google</span>
        <google
          @toggleShowRecharge="toggleShowRecharge"
          :leave="activeTab"
        ></google>
      </el-tab-pane> -->
    </el-tabs>
    <!-- 充值 -->
    
  </el-container>
</template>

<script>
import Facebook from './components/Facebook.vue';
// import Tiktok from './components/Tiktok.vue';
// import Google from './components/Google.vue';
import { queryAgency } from '@/api/common/account';

export default {
  components: {
    Facebook,
    // Tiktok,
    // Google,
    // Recharge,
  },
  data() {
    return {
      activeTab: 'Facebook',
      showRecharge: false,
      queryAgencyList: [],
    };
  },
  methods: {
    toggleShowRecharge(bool = false) {
      this.showRecharge = bool;
    },
    // 账单简称
    // 获取账单简称列表
    getQueryAgencyList() {
      queryAgency().then((res) => {
        this.queryAgencyList = res.data;
      });
    },
  },
  created() {
    this.getQueryAgencyList();
  },
};
</script>
<style lang="scss">
.propertyList {
  .el-tabs__content {
    padding: 0;
  }
  .el-table .caret-wrapper {
    width: 0;
  }
}
</style>
