<template>
  <el-dialog
    title="调整额度"
    width="800px"
    :visible="show"
    @close="$emit('toggleShow')"
    :close-on-click-modal="false"
  >
    <div class="accountRecharge">
      <el-container>
        <el-main>
          <el-form
            :model="openAccount"
            :rules="rules"
            label-position="top"
            ref="form"
          >
            <el-form-item
              label="平台："
              prop="platform"
            >
              <el-select
                placeholder="选择代理账单"
                class="formItem"
                v-model="openAccount.platform"
              >
                <el-option
                  v-for="platform in platforms"
                  :label="platform.value"
                  :value="platform.value"
                  :key="platform.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="广告账户 ID："
              prop="account_id"
            >
              <el-input
                placeholder="广告账户 ID"
                v-model="openAccount.account_id"
                @blur="findAgencyByAdAccountId"
                @change="changeAccountId"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="账单简称："
              prop="id"
            >
              <el-select
                placeholder="选择代理账单"
                class="formItem"
                filterable
                v-model="openAccount.id"
                :loading="loadingVlToken"
                :disabled="!openAccount.account_id"
              >
                <el-option
                  :label="query.agency"
                  :value="query.id"
                  v-for="query in queryAgency"
                  :key="query.id"
                  :disabled="!query.hasConfigVlToken"
                >
                  <div class="dp-flex justify-btw">
                    <span>{{ query.agency }}</span>
                    <span v-if="!query.hasConfigVlToken">（请配置vlToken）</span>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="充值金额："
              prop="amount"
            >
              <el-input-number
                placeholder="请输入充值金额"
                v-model="openAccount.amount"
                :min="0"
              ></el-input-number>
            </el-form-item>
          </el-form>
        </el-main>
        <el-footer class="border-top-1 dp-flex justify-end align-item-c">
          <el-button
            type="primary"
            @click="checkData"
            :loading="loading"
            >确认</el-button
          >
          <el-button
            type="info"
            @click="$emit('toggleShow')"
            :loading="loading"
            >取消</el-button
          >
        </el-footer>
      </el-container>
    </div>
  </el-dialog>
</template>

<script>
import { accountRecharge, findAgencyByAdAccountId } from '@/api/accountRecharge';
import { queryAgency } from '@/api/common/account';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    
    queryAgencyList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const validatorId = async (rule, value, callback) => {
      const selectData = this.queryAgency.find((item) => item.id === value);
      if (selectData && selectData.hasConfigVlToken) {
        return callback();
      }
      callback('缺少Vision Line token，请去配置Vision Line token');
    };

    return {
      loading: false, // 申请loading
      queryAgency: [],
      // queryAgencyList: [],
      showGetId: false,
      loadingVlToken: false,
      platforms: [
        {
          value: 'facebook',
        },
        {
          value: 'tiktok',
        },
        {
          value: 'google',
        },
      ],
      rules: {
        platform: [
          {
            required: true,
            message: '请选择平台',
            trigger: 'blur',
          },
        ],
        id: [
          {
            required: true,
            message: '请选择代理账单',
            trigger: 'blur',
          },
          {
            validator: validatorId,
            required: true,
            message: '请选择代理账单',
          },
        ],
        account_id: [
          {
            required: true,
            message: '请输入广告账户ID',
            trigger: 'blur',
          },
        ],
        amount: [
          {
            required: true,
            message: '请输入金额',
            trigger: 'blur',
          },
        ],
      },
      openAccount: {
        id: '',
        account_id: '',
        amount: null,
        platform: 'facebook',
      },
    };
  },
  methods: {
    // 获取账单简称
    findAgencyByAdAccountId() {
      let params = {
        adAccountId: this.openAccount.account_id,
      };
      this.loadingVlToken = true;
      findAgencyByAdAccountId(params)
        .then((res) => {
          if (res.data) {
            this.queryAgency = [{ ...res.data }];
            this.$nextTick(() => {
              this.openAccount.id = res.data.id;
            });
          } else {
            this.queryAgency = [...this.queryAgencyList];
          }
        })
        .finally(() => {
          this.loadingVlToken = false;
          this.queryAgency = [...this.queryAgencyList];
        });
    },
    // 修改账户id
    changeAccountId() {
      this.openAccount.id = null;
      this.queryAgency = [];
    },
    checkData() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = {
            agencyId: this.openAccount.id,
            account_id: this.openAccount.account_id,
            amount: this.openAccount.amount,
            platform: this.openAccount.platform,
          };
          this.submitForm(params);
        }
      });
    },
    submitForm(params) {
      this.loading = true;
      accountRecharge(params)
        .then((res) => {
          if (res.code == 0) {
            this.$message('充值提交成功，请在列表中查看代理充值结果');
            this.$emit('toggleShow');
            this.$emit('refreshList');
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setShowGetId(bool) {
      this.showGetId = bool;
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.$refs.form.resetFields();
        this.openAccount = {
          id: '',
          account_id: '',
          amount: null,
          platform: 'facebook',
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.accountRecharge {
  width: 100%;
  border-radius: 4px;
  background: #fff;

  .header {
    border-bottom: 1px solid #eee;
  }

  .formItem {
    width: 100%;
  }

  .lookGetIdImg {
    width: 100%;
  }
}
</style>
