<template>
  <div class="PixelListFb">
    <page-table
      :searchs="searchs"
      :btns="btns"
      :tableColumn="tableColumn"
      :formInitData="formInitData"
      :immediateGetList="leave == 'Facebook' && leave2 == 'pixel'"
      :API="API"
      ref="pageTable"
    ></page-table>
    <!-- 调整像素 -->
    <bind-fixel-fb
      :show="showFixel"
      @toggleShow="toggleShowFixel"
      :queryAgencyList="queryAgencyList"
      @refreshList="refreshList"
    ></bind-fixel-fb>
  </div>
</template>
<script>
import PageTable from '@/components/common/PageTable.vue';
import BindFixelFb from './BindFixelFb.vue';
import BindBmFb from './BindBmFb.vue';
import { queryAccountBindPage } from '@/api/PropertyList';

export default {
  components: { PageTable, BindBmFb, BindFixelFb },
  props: {
    leave: {
      type: String,
    },
    leave2: {
      type: String,
    },
    queryAgencyList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formInitData: {
        platefrom: 'facebook',
        type: 'PIXEL_BIND',
      },
      btns: [
        {
          name: '调整像素',
          click: () => this.toggleShowFixel(true),
          config: {
            type: 'primary',
          },
        },
      ],
      tableColumn: [
        {
          label: 'ID',
          width: 50,
          prop: 'id',
        },
        {
          label: '账户ID',
          prop: 'adAccountId',
        },
        {
          label: '账单简称',
          prop: 'agency.agencyName',
        },
        {
          label: '开户主体',
          prop: 'entityName',
        },
        {
          label: '类型',
          prop: 'bindType',
          width: 150,
          formatter: (row) => {
            if (row.bindType == 1) return '绑定';
            if (row.bindType == 2) return '解绑';
          },
        },
        {
          label: '像素ID',
          prop: 'assetId',
        },
        {
          label: '操作时间',
          prop: 'createTime',
        },
        {
          label: '申请ID',
          prop: 'applyId',
          width: 150,
        },
        {
          label: '状态',
          prop: 'status',
          width: 150,
          formatter: (row) => {
            if (row.status == 'SUCCESS') return <span class='status-409eff'>成功</span>;
            if (row.status == 'FAILED')
              return (
                <el-tooltip
                  effect='dark'
                  content={row.failReason}
                  placement='bottom'
                >
                  <span class='status-e44646'>失败</span>
                </el-tooltip>
              );
            return '处理中';
          },
        },
      ],
      searchs: [
        {
          type: 'input',
          prop: 'accountId',
          formOptions: {
            placeholder: '账户ID，多个以,隔开',
            clearable: true,
          },
        },
        {
          type: 'select',
          prop: 'agencyId',
          formOptions: {
            filterable: true,
            placeholder: '账单简称',
            clearable: true,
          },
          options: this.queryAgencyList,
          optionConfig: {
            label: 'agency',
            value: 'id',
          },
        },
        {
          type: 'input',
          prop: 'entityName',
          formOptions: {
            placeholder: '开户主体名称',
            clearable: true,
          },
        },
        {
          type: 'select',
          prop: 'bindAssetStatus',
          formOptions: {
            placeholder: '状态',
            clearable: true,
          },
          options: [
            {
              label: '处理中',
              value: 'IN_HAND',
            },
            {
              label: '成功',
              value: 'SUCCESS',
            },
            {
              label: '失败',
              value: 'FAILED',
            },
          ],
        },
        {
          type: 'date-picker',
          prop: 'time',
          startTimeKey: 'startDate',
          endTimeKey: 'endDate',
          formOptions: {
            type: 'daterange',
            placeholder: '时间',
            clearable: true,
          },
        },
      ],
      showFixel: false,
      API: queryAccountBindPage,
    };
  },
  methods: {
    // 像素显示转换
    toggleShowFixel(bool = false) {
      this.showFixel = bool;
    },
    refreshList() {
      this.$refs.pageTable.getList({
        pageNumber: 0,
      });
    },
  },
  watch: {
    queryAgencyList(val) {
      this.searchs.map((item, index) => {
        if (item.prop == 'agencyId') {
          this.$set(this.searchs[index], 'options', val);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
